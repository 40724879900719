import React, { useEffect, useState } from "react";
import { Button } from "@chemaxon/design-system";
import ViewContainer from "src/ui/components/view-container/ViewContainer";
import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import ContainerWithLoaderAndError from "src/ui/components/container-with-loader-and-error/ContainerWithLoaderAndError";

import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Tooltip from "@mui/material/Tooltip";
import Subheader from "src/ui/components/subheader/Subheader";

import "src/ui/views/projects/list/ListProjectsView.scss";
import { Restricted } from "src/ui/utils/Restricted";
import { Group } from "src/ui/models/Group";
import { useGetGroups, useRemoveGroup } from "src/ui/services/GroupService";
import MoreMenu from "src/ui/components/more-menu/MoreMenu";
import RemoveButton from "src/ui/components/more-menu/RemoveButton";
import ManageButton from "src/ui/components/more-menu/ManageButton";
import SynergyMUIDataTableSearchBox from "src/ui/components/mui-datatables-search-box/SynergyMUIDataTableSearchBox";
import CheckBoxConfirmDialog from "src/ui/components/confirmation/CheckBoxConfirmDialog";
import AddGroup from "src/ui/views/groups/add/AddGroup";
import EditGroup from "src/ui/views/groups/edit/EditGroup";
import { usePermissions } from "src/ui/utils/usePermissions";
import { EditOff } from "@mui/icons-material";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";

function ListGroupView() {
  const { checkPermission } = usePermissions();
  const getGroupsQueryResult = useGetGroups();
  const mutateRemoveGroup = useRemoveGroup();
  const [groupToRemove, setGroupToRemove] = useState<null | Group>(null);
  const [groupToEdit, setGroupToEdit] = useState<null | Group>(null);
  const [isInAddGroupState, setAddGroupState] = useState<boolean>(false);

  useEffect(() => {
    if (mutateRemoveGroup.isSuccess) {
      hideConfirmDialog();
    }
  }, [mutateRemoveGroup.isSuccess]);

  const groups =
    adaptUseQueryResponseForContainerWithLoader(getGroupsQueryResult);

  const hideConfirmDialog = () => {
    setGroupToRemove(null);
  };

  const hideCreateGroupDialog = () => {
    setAddGroupState(false);
  };

  const hideEditGroupDialog = () => {
    setGroupToEdit(null);
  };

  const createGroupButton = () => {
    const hasCreatePermission = checkPermission(
      PlatformDomainObjectType.GROUP,
      PlatformPermission.CREATE,
    );

    return hasCreatePermission ? (
      <Button
        colorVariant="primary"
        id="addGroupButton"
        onClick={() => setAddGroupState(true)}
      >
        Create new group
      </Button>
    ) : null;
  };

  const getMuiTableOptions = (): MUIDataTableOptions => {
    return {
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <SynergyMUIDataTableSearchBox
            searchText={searchText}
            onSearch={handleSearch}
            onHide={hideSearch}
            options={options}
          />
        );
      },
      filterType: "dropdown",
      searchOpen: true,
      download: false,
      print: false,
      selectableRows: "none",
      elevation: 0,
      filter: false,
      viewColumns: false,
      sortOrder: {
        name: "ID",
        direction: "asc",
      },
      customSearch: (rawSearchQuery, currentRow, columns) => {
        const caseSensitive = false;
        let isFound = false;
        const searchQuery = caseSensitive
          ? rawSearchQuery
          : rawSearchQuery.toLowerCase();
        currentRow.forEach((col, i) => {
          if (!columns[i].searchable) {
            return;
          }

          let textInRow = i === 0 ? col["groupName"] : col.toString();
          if (!caseSensitive) {
            textInRow = textInRow.toString().toLowerCase();
          }

          if (textInRow.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
    };
  };

  const getTableColumns = () => {
    const columns = [];

    columns.push({
      name: "Group name",
      options: {
        filter: true,
        sort: true,
        searchable: true,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 320,
            },
          };
        },
        customBodyRender: renderGroupName,
      },
    });
    columns.push({
      name: "Members",
      options: {
        filter: true,
        sort: true,
        searchable: false,
        customBodyRender: renderGroupSize,
      },
    });
    columns.push({
      name: "Auto-assign",
      options: {
        filter: true,
        sort: true,
        searchable: false,
        setCellHeaderProps: () => {
          return {
            style: {
              width: 220,
            },
          };
        },
        customBodyRender: renderAutoAssign,
      },
    });
    columns.push({
      name: "",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: renderDropdownMenu,
      },
    });

    return columns;
  };

  const getTableData = (groups: Group[]): DataRow[] =>
    groups.map(group => [
      { groupName: group.groupName, isAdminGroup: group.isTeamAdminGroup },
      group.memberIds.length,
      group.autoAssign,
      group,
    ]);

  const renderDropdownMenu = (group: Group) => (
    <Restricted
      to={{
        platformDomainObjectType: PlatformDomainObjectType.GROUP,
        platformPermission: PlatformPermission.WRITE,
      }}
    >
      <MoreMenu
        id={group.id}
        options={[
          {
            key: group.id + "_edit",
            option: <ManageButton onClick={() => editGroup(group)} />,
          },
          {
            key: group.id + "_remove",
            option: !group.isTeamAdminGroup && (
              <RemoveButton onClick={() => setGroupToRemove(group)} />
            ),
          },
        ]}
      />
    </Restricted>
  );

  const editGroup = (group: Group) => {
    setGroupToEdit(group);
  };

  const renderGroupName = (data: NameColumnData) => {
    return (
      <div style={{ verticalAlign: "middle", display: "flex" }}>
        {renderName(data.groupName, 40)}
        {data.isAdminGroup ? (
          <EditOff fontSize={"small"} sx={{ marginLeft: 3 }} />
        ) : null}
      </div>
    );
  };

  const renderGroupSize = (size: number) => {
    return renderName(size + " members", 15);
  };

  const renderAutoAssign = (autoAssign: boolean) => {
    return renderName(autoAssign ? "on " : "off", 5);
  };

  const renderName = (name: string, charLength: number) => {
    if (name && name.length > charLength) {
      const truncName = name.substring(0, charLength - 1) + "...";

      return (
        <Tooltip title={name}>
          <span>{truncName}</span>
        </Tooltip>
      );
    }
    return <span>{name}</span>;
  };

  return (
    <React.Fragment>
      <Restricted
        to={{
          platformDomainObjectType: PlatformDomainObjectType.GROUP,
          platformPermission: PlatformPermission.WRITE,
        }}
      >
        <Subheader
          title={"Groups"}
          button={
            <Restricted
              to={{
                platformDomainObjectType: PlatformDomainObjectType.GROUP,
                platformPermission: PlatformPermission.CREATE,
              }}
            >
              {createGroupButton()}
            </Restricted>
          }
        />
        <ViewContainer id={"listGroupsContainer"}>
          <ContainerWithLoaderAndError
            data={groups}
            content={(groups: Group[]) => {
              const tableColumns = getTableColumns();
              const tableData = getTableData(groups);
              return (
                <div className="listGroupsView">
                  <MUIDataTable
                    title={""}
                    columns={tableColumns}
                    data={tableData}
                    options={getMuiTableOptions()}
                  />
                  <CheckBoxConfirmDialog
                    isShown={groupToRemove !== null}
                    title={`Delete ${
                      groupToRemove ? groupToRemove.groupName : null
                    } group?`}
                    checkBoxLabel="I understand that deleting group is pemanent and cannot be undone."
                    confirmButtonLabel={"Yes, delete it"}
                    submit={() =>
                      groupToRemove &&
                      mutateRemoveGroup.mutate(groupToRemove.id)
                    }
                    cancel={() => hideConfirmDialog()}
                    destructive={true}
                    loading={mutateRemoveGroup.isPending}
                    divider={false}
                  />
                </div>
              );
            }}
          />
        </ViewContainer>
        <AddGroup
          onCancel={() => hideCreateGroupDialog()}
          isShown={isInAddGroupState}
        />
        <EditGroup
          onCancel={() => hideEditGroupDialog()}
          isShown={groupToEdit != null}
          group={groupToEdit}
        />
      </Restricted>
    </React.Fragment>
  );
}

type DataRow = [
  nameColumnData: NameColumnData,
  groupSize: number,
  autoAssign: boolean,
  group: Group,
];

type NameColumnData = {
  groupName: string;
  isAdminGroup: boolean;
};

export default ListGroupView;
