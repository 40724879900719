import {
  PermissionMap,
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";

export const createCheckPermission =
  (permissions: PermissionMap) =>
  (
    platformDomainObjectType: PlatformDomainObjectType,
    platformPermission: PlatformPermission,
  ) => {
    const resolvedPermission = permissions[platformDomainObjectType];

    if (resolvedPermission) {
      return (
        resolvedPermission.includes(platformPermission) ||
        resolvedPermission.includes(PlatformPermission.ALL)
      );
    }

    return false;
  };

export const createIsSuperAdmin =
  (checkPermission: ReturnType<typeof createCheckPermission>) => () => {
    const permissions: Parameters<typeof checkPermission>[] = [
      [PlatformDomainObjectType.USER, PlatformPermission.WRITE],
      [PlatformDomainObjectType.GROUP, PlatformPermission.WRITE],
      [PlatformDomainObjectType.PROJECT, PlatformPermission.WRITE],
      [PlatformDomainObjectType.RESOURCE, PlatformPermission.WRITE],
    ];

    return permissions.every(permissions => checkPermission(...permissions));
  };
