import { useState } from "react";
import { Typography, UserMenu } from "@chemaxon/design-system";
import { Stack } from "@mui/material";

import {
  PlatformDomainObjectType,
  PlatformPermission,
} from "src/ui/models/Permission";
import TeamService from "src/ui/services/TeamService";
import ConfirmDialog from "src/ui/components/confirmation/ConfirmDialog";
import Dialog from "src/ui/components/dialog/Dialog";
import { usePermissions } from "src/ui/utils/usePermissions";
import {
  useGetCurrentTeam,
  useGetCurrentUser,
} from "src/ui/services/UserInfoService";
import { useGetProjects } from "src/ui/services/ProjectService";
import { adaptUseQueryResponseForContainerWithLoader } from "src/ui/utils/queryHelpers";
import { useGetGroups } from "src/ui/services/GroupService";
import { useGetEnvQuery } from "src/ui/services/EnvInfoService.ts";

const HELP_USER =
  "https://docs.chemaxon.com/display/docs/chemaxon-cloud_user-guide_team-member_index.md";
const HELP_TEAM_ADMIN =
  "https://docs.chemaxon.com/display/docs/chemaxon-cloud_user-guide_team-admin_index.md";
const HELP_SYSTEM_ADMIN =
  "https://docs.chemaxon.com/display/docs/chemaxon-cloud_user-guide_system-administrator_index.md";
const CXN_EUSA_URL =
  "https://docs.chemaxon.com/display/docs/End+User+Subscription+Agreement+-+EUSA";
const CXN_PRIVACY_POLICY_URL =
  "https://docs.chemaxon.com/display/docs/Privacy+Policy+for+ChemAxon+Synergy";

export default function ProfileElement() {
  const [signingOut, setSigningOut] = useState(false);
  const [showingUserDetails, setShowingUserDetails] = useState(false);
  const [showingAbout, setShowingAbout] = useState(false);

  const { checkPermission } = usePermissions();
  const getEnvQueryResult = useGetEnvQuery();
  const getGroupsQueryResult = useGetGroups();
  const getProjectsQueryResult = useGetProjects();
  const getCurrentTeamQueryResult = useGetCurrentTeam();
  const getCurrentUserQueryResult = useGetCurrentUser();

  const projects = adaptUseQueryResponseForContainerWithLoader(
    getProjectsQueryResult,
  );
  const groups =
    adaptUseQueryResponseForContainerWithLoader(getGroupsQueryResult);
  const envInfo = getEnvQueryResult.data;
  const currentTeam = getCurrentTeamQueryResult.data;
  const currentUser = getCurrentUserQueryResult.data;

  const performSignOut = () => {
    setSigningOut(false);
    document.logoutForm.submit();
  };

  const openHelp = () => {
    if (
      checkPermission(PlatformDomainObjectType.TEAM, PlatformPermission.CREATE)
    ) {
      window.open(HELP_SYSTEM_ADMIN, "_blank", "noopener");
    } else if (
      checkPermission(PlatformDomainObjectType.USER, PlatformPermission.WRITE)
    ) {
      window.open(HELP_TEAM_ADMIN, "_blank", "noopener");
    } else {
      window.open(HELP_USER, "_blank", "noopener");
    }
  };

  const openEusa = () => {
    if (!currentTeam) {
      return;
    }

    if (currentTeam.hasCustomEusa) {
      window.open(
        TeamService.getCustomEusaUrl(currentTeam.id),
        "_blank",
        "noopener",
      );
    } else {
      window.open(CXN_EUSA_URL, "_blank", "noopener");
    }
  };

  const openPrivacyPolicy = () => {
    window.open(CXN_PRIVACY_POLICY_URL, "_blank", "noopener");
  };

  const getUserDetailsDialogContent = () => {
    if (!currentUser) {
      return null;
    }
    const projectsForUser = currentUser.projects.map(projectId => {
      const project =
        !!projects && projects?.find(project => project.id === projectId);
      return project ? <li key={project.id}>{project.displayId}</li> : null;
    });

    const groupsForUser = currentUser.groups.map(groupId => {
      const group = !!groups && groups.find(group => group.id === groupId);
      return group ? <li key={group.id}>{group.groupName}</li> : null;
    });

    return (
      <Stack spacing={4}>
        <section>
          <Typography variant="subtitle1" component="h3">
            Projects
          </Typography>
          {projectsForUser.length === 0 ? (
            <Typography fontStyle="italic">
              You are not member of any project.
            </Typography>
          ) : (
            <ul>{projectsForUser}</ul>
          )}
        </section>

        <section>
          <Typography variant="subtitle1" component="h3">
            Groups
          </Typography>
          {groupsForUser.length === 0 ? (
            <Typography fontStyle="italic">
              You are not member of any group.
            </Typography>
          ) : (
            <ul>{groupsForUser}</ul>
          )}
        </section>
      </Stack>
    );
  };

  const getAboutDialogContent = () => {
    const buildInfo = envInfo?.buildInfo;

    return buildInfo ? (
      <Stack spacing={4}>
        <Typography variant="subtitle1" component="h3">
          Chemaxon Cloud
        </Typography>

        <section>
          <Typography>Version: {buildInfo.version}</Typography>
          <Typography>Build number: {buildInfo.buildNumber}</Typography>
          <Typography>Commit: {buildInfo.commitId}</Typography>
        </section>

        <section>
          <Typography>Supported browsers:</Typography>
          <Typography>
            Chrome, Firefox, Safari, Internet Explorer 11 and Microsoft Edge
          </Typography>
        </section>

        <Typography variant="label2" component="small">
          Copyright &copy; 2023 Chemaxon Ltd
        </Typography>
      </Stack>
    ) : (
      <Typography>No info available</Typography>
    );
  };

  const getUserMenuActions = () => {
    const userMenuActions = [];

    userMenuActions.push({
      href: "#",
      label: "User details",
      onClick: () => setShowingUserDetails(true),
    });

    if (
      !checkPermission(PlatformDomainObjectType.TEAM, PlatformPermission.CREATE)
    ) {
      userMenuActions.push({
        href: "/notifications",
        label: "Notification settings",
      });
    }

    userMenuActions.push({
      href: "#",
      label: "About",
      onClick: () => setShowingAbout(true),
    });

    userMenuActions.push({
      href: "#",
      label: "Help",
      onClick: openHelp,
    });

    userMenuActions.push({
      href: "#",
      label: "Subscription Agreement",
      onClick: openEusa,
    });

    userMenuActions.push({
      href: "#",
      label: "Privacy Policy",
      onClick: openPrivacyPolicy,
    });

    userMenuActions.push({
      href: "#",
      label: "Sign out",
      onClick: () => setSigningOut(true),
    });

    return userMenuActions;
  };

  const getUserNameInitials = () => {
    if (!currentUser) {
      return "";
    }

    const userDetails = currentUser.idpUserDetails;
    let userInitials = "";

    if (userDetails.name) {
      userInitials = userDetails.name
        .trim()
        .split(" ")
        .map(name => name[0])
        .join("");
    }

    return userInitials;
  };

  return (
    <>
      <UserMenu
        userInitials={getUserNameInitials()}
        actions={getUserMenuActions()}
      />

      <Dialog
        title="User details"
        open={showingUserDetails}
        content={getUserDetailsDialogContent()}
        actions={[
          {
            id: "user-details-close",
            label: "Close",
            colorVariant: "secondary",
            onClick: () => setShowingUserDetails(false),
          },
        ]}
        onClose={() => setShowingUserDetails(false)}
      />

      <Dialog
        title="About"
        open={showingAbout}
        content={getAboutDialogContent()}
        actions={[
          {
            id: "about-close",
            label: "Close",
            colorVariant: "secondary",
            onClick: () => setShowingAbout(false),
          },
        ]}
        onClose={() => setShowingAbout(false)}
      />

      <ConfirmDialog
        isShown={signingOut}
        title="Are you sure you want to sign out?"
        subtitle="You will need to sign out from all applications of Chemaxon Cloud separately. Please consider signing off from your identity provider as well."
        submit={performSignOut}
        cancel={() => setSigningOut(false)}
      />
    </>
  );
}
