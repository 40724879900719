import {
  Application,
  ApplicationIdentity,
  ApplicationInfo,
  ApplicationType,
  Category,
  DeeplinksFeature,
  Feature,
  FeatureNamespace,
  GroupType,
  ProductIdentity,
  Status,
  VendorType,
} from "src/ui/models/Application";
import { Product } from "src/ui/models/Product";
import { Group } from "src/ui/models/Group";
import { Team } from "src/ui/models/Team";

/**
 * Retrieve url of the application
 */
function getApplicationUrl(application: Application): string {
  const feature = application.features.find(
    isFeatureNamespace(FeatureNamespace.Web),
  );

  return (
    (feature ? feature.attributes.mainEntryPoint : application.address) ?? ""
  );
}

function filterRealApplications(apps: Application[]): Application[] {
  return apps.filter(app => {
    return (
      app.status !== Status.Waiting &&
      app.identities.find((identity): identity is ApplicationIdentity => {
        return (
          (identity.category === Category.Application &&
            identity.type === ApplicationType.Webapp) ||
          // TODO deprecated bellow and should be deleted it is kept for now for certain time for backwards
          // compatibility
          identity.category === Category.Application
        );
      })
    );
  });
}

function filterTeamApplications(
  apps: Application[],
  teamId: number,
): Application[] {
  return apps.filter(app => app.team === teamId);
}

function filterApplicationsByVisibility(
  apps: Application[],
  group: string,
): Application[] {
  return apps.filter(app => {
    const visibilityFeature = app.features.find(
      isFeatureNamespace(FeatureNamespace.Visibility),
    );
    return (
      !app.hidden &&
      (visibilityFeature
        ? visibilityFeature.attributes?.groups?.includes(group) ?? true
        : true)
    );
  });
}

function getDefaultValueForHiddenField(appInfo: ApplicationInfo) {
  return (
    appInfo?.identities?.find(
      (identity): identity is ApplicationIdentity =>
        identity.category === Category.Application,
    )?.type === ApplicationType.Service
  );
}

const getOwnApplications = (
  applications: Application[],
  currentGroups: Group[],
  currentTeam?: Team,
): Application[] => {
  const group = currentGroups.find(
    ({ groupName }) => groupName === "SUPER_ADMIN",
  );
  const groupName = group?.groupName ?? "";
  return applications && currentTeam
    ? filterApplicationsByVisibility(
        filterTeamApplications(applications, currentTeam.id),
        groupName,
      )
    : [];
};

const getProduct = (application: Application, products: Product[]) => {
  const isChemaxonApplication = application.identities.some(
    identity =>
      identity.category === Category.Vendor &&
      identity.type === VendorType.Chemaxon,
  );
  if (isChemaxonApplication) {
    const productIdentity = application.identities.find(
      (identity): identity is ProductIdentity =>
        identity.category === Category.Product,
    );
    if (productIdentity) {
      return products.find(product => product.id === productIdentity.type);
    }
  }
  return null;
};

const getDeepLinks = (
  application: Application,
): DeeplinksFeature["attributes"]["links"] | null => {
  const deepLinksFeature = application.features.find(
    isFeatureNamespace(FeatureNamespace.Deeplinks),
  );
  if (deepLinksFeature) {
    return deepLinksFeature.attributes.links;
  }
  // TODO: temporary hardcoded deep links for compreg and assay - remove once these apps start to provide them
  const compregId = application.identities.find(
    identity => identity.type === GroupType.Registration,
  );
  if (compregId) {
    return [
      {
        name: "Register new compound",
        url: application.address + "/client/index.html#/registration",
      },
      {
        name: "Upload file",
        url: application.address + "/client/index.html#/upload",
      },
      {
        name: "Staging area",
        url:
          application.address +
          "/client/index.html#/staging?tab=allSubmissions",
      },
    ];
  }
  const assayId = application.identities.find(
    identity => identity.type === GroupType.Aggregation,
  );
  if (assayId) {
    return [
      {
        name: "Upload file",
        url: application.address + "app/#assay/task/upload/select",
      },
      { name: "Tasks", url: application.address + "app/#assay/task" },
    ];
  }
  return null;
};

const isFeatureNamespace =
  <TFeatureNamespace extends FeatureNamespace>(
    featureNamespace: TFeatureNamespace,
  ) =>
  (
    feature: Feature,
  ): feature is Extract<Feature, { namespace: TFeatureNamespace }> =>
    feature.namespace === featureNamespace;

export {
  filterRealApplications,
  filterTeamApplications,
  filterApplicationsByVisibility,
  getApplicationUrl,
  getDeepLinks,
  getDefaultValueForHiddenField,
  getOwnApplications,
  getProduct,
  isFeatureNamespace,
};
